const axios = require('axios');

const initialState = {
  status: 'loading',
  saving: false,
	docs: new Map()
}

export default function counterReducer(state = initialState, action) {
	let newState = {...state}
	if (action.docId && !newState.docs.has(action.docId)) {
		newState.docs.set(action.docId, {})
	}

  switch (action.type) {
		case 'issues/issueIdsUpdated':
			return {
				...state,
				issueIds: action.data
			}
		case 'issue/destroyed': {
			console.log("Processing removal: " + action.payload)
			newState.docs.delete(action.payload)
			newState.issueIds = newState.issueIds.filter(issueId => issueId !== action.payload)
			return newState
		}
    case 'counter/counterLoading': {
			newState.docs.get(action.docId).status = 'loading'
			newState.status = 'loading'
			return newState;
		}
    case 'counter/counterLoaded': {
			newState.docs.get(action.docId).status = 'idle'
			if (Array.from(newState.docs.values()).every(doc => doc.status === 'idle')) {
				newState.status = 'idle'
			}
			newState.docs.get(action.docId).data = action.data
			console.log(newState)
			return newState;
		}
    case 'saving/started':
      console.log("Saving started")
			newState.docs.get(action.docId).saving = true
			newState.saving = true
			return newState;
    case 'saving/finished':
      console.log("Saving finished")
			newState.docs.get(action.docId).saving = false
			if (Array.from(newState.docs.values()).every(doc => !doc.saving)) {
				newState.saving = false
			}
			return newState
    default:
      return state
  }
}

export async function createIssue(dispatch, getState) {
  axios.post('https://worker.datainfra-justinc.workers.dev/createIssue')
    .then(response => {
      dispatch({type: 'issue/create', payload: response.data})
    }).catch(error => {
      console.log(error);
    });
    return false;
}

export async function destroyIssue(issueId) {
	return async (dispatch, getState) => {
		axios.post('https://worker.datainfra-justinc.workers.dev/destroyIssue')
			.then(response => {
				dispatch({type: 'issue/destroyed', payload: issueId})
			}).catch(error => {
				console.log(error);
			});
		return false;
	}
}
