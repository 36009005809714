import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import Issues from './components/Issues'
import IssueForm from './components/IssueForm'
import SavingIndicator from './components/SavingIndicator'
import counter from './reducers'
import localForage from "localforage";

const Automerge = require('automerge')
const axios = require('axios');

class ApiClient {
	constructor(dispatch) {
		this.dispatch = dispatch
		this.docClients = new Map()
	}

	updateDocumentIds(documentIds) {
		const docIds = new Set(documentIds)
		for (let key of this.docClients.keys()) {
			if (!docIds.has(key)) {
				this.destroyDocument(key)
			}
		}

		for (let key of docIds) {
			if (!this.docClients.has(key)) {
				let client = new DocClient(key, this.dispatch)
				this.docClients.set(key, client)
				client.start()
			}
		}
	}

	destroyDocument(documentId) {
		let client = this.docClients.get(documentId)
		if (client) {
			client.destroy()
			this.docClients.delete(documentId)
		}
	}
}

class DocClient {
  // TODO: use https://lorefnon.tech/2018/09/23/using-google-diff-match-patch-with-automerge-text/
  // for incremental editing of text data.
  constructor(docId, dispatch) {
		this.docId = docId;
    this.dispatch = dispatch;
    this.syncState = Automerge.Backend.initSyncState();
    this.reconnecting = false;
		this.finished = false;
  }

  connect() {
    if (!this.finished && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
      this.syncState = Automerge.Backend.decodeSyncState(Automerge.Backend.encodeSyncState(this.syncState))
			console.log("Starting wss://worker.datainfra-justinc.workers.dev/websocket/" + this.docId)
      this.ws = new WebSocket("wss://worker.datainfra-justinc.workers.dev/websocket/" + this.docId)
      this.ws.addEventListener("message", event => this.eventReceived(event))
      this.ws.addEventListener("open", event => {
        if (this.state) {
          this.sendStateUpdated()
        } else {
          this.ws.send(JSON.stringify({type: 'getDocument'}))
        }
      })
      this.ws.addEventListener("error", event => console.log(event));
      this.ws.addEventListener("close", event => this.reconnect());
    }
  }

  eventReceived(event) {
    console.log("Received Event:")
    console.log(event)
    let message = JSON.parse(event.data)

    switch (message.type) {
      case 'loadDocument':
        let docBytes = new Uint8Array(message.payload)
        this.state = Automerge.load(docBytes)
        break;
      case 'updateDocument':
        // eslint-disable-next-line
        const [nextState, nextSyncState, patch] = Automerge.receiveSyncMessage(
          this.state,
          this.syncState,
          new Uint8Array(message.payload)
        )
        this.syncState = nextSyncState
        this.state = nextState
        break;
      default:
        console.log("Unknown command received: " + message.type)
    }
  }

  updateDoc(updateFunc) {
    this.dispatch({type: 'saving/started', docId: this.docId})
    this.state = Automerge.change(this.state, updateFunc)
  }

  sendStateUpdated() {
    const [nextSyncState, syncMessage] = Automerge.generateSyncMessage(
      this.state,
      this.syncState
    )
    this.syncState = nextSyncState;
    if (syncMessage) {
      const syncMessageData = Array.from(syncMessage)
			try {
				this.ws.send(JSON.stringify({type: 'updateDocument', payload: syncMessageData}))
			} catch {
				console.log("Message send failed")
			}
    } else {
      this.dispatch({type: 'saving/finished', docId: this.docId})
    }
  }

  set state(newState) {
    this._state = newState;
    this.save()
    this.dispatch({type: 'counter/counterLoaded', data: this._state, docId: this.docId})
    this.sendStateUpdated()
  }

  get state() {
    return this._state;
  }

  save() {
    if (this.state) {
			// This should actually do a merge with what's there, since it can
			// be touched in multiple windows
      localForage.setItem(this.docId, Automerge.save(this.state))
    }
  }

  start() {
    localForage.getItem(this.docId).then((value) => {
      if (value) {
        // TODO: syncState should also be saved and loaded here.
        this._state = Automerge.load(value);
        this.dispatch({type: 'counter/counterLoaded', data: this._state, docId: this.docId})
      }
    }).finally(() => {
      this.connect()
    })
  }

  reconnect() {
    if (!this.finished && !this.reconnecting) {
      this.reconnecting = true;
      setTimeout(() => {
        console.log("Trying to reconnect")
        this.connect()
        this.reconnecting = false;
      }, 1000);
    }
  }

	close() {
		this.finished = true
		this.ws.close()
	}

	destroy() {
		this.dispatch({type: 'saving/started', docId: this.docId})
		axios.post('https://worker.datainfra-justinc.workers.dev/destroyIssue', this.docId)
			.then(response => {
				console.log("Document destroyed: " + this.docId)
				this.close()
				this.dispatch({type: 'issue/destroyed', payload: this.docId})
				this.dispatch({type: 'saving/finished', docId: this.docId})
			}).catch(error => {
				console.log(error);
			});
	}
}

class IssuesClient {
  constructor(dispatch, apiClient) {
    this.dispatch = dispatch;
		this.apiClient = apiClient;
    this.reconnecting = false;
  }

  connect() {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
      this.ws = new WebSocket("wss://worker.datainfra-justinc.workers.dev/issuesWebsocket")
      this.ws.addEventListener("message", event => this.eventReceived(event))
      this.ws.addEventListener("error", event => console.log(event));
      this.ws.addEventListener("close", event => this.reconnect());
    }
  }

  eventReceived(event) {
    console.log("Received Event:")
    console.log(event)
    let message = JSON.parse(event.data)

    switch (message.type) {
      case 'updatedIssueIds':
				const issueIds = message.payload
				console.log("Fetched Ids: " + JSON.stringify(issueIds))
				this.apiClient.updateDocumentIds(issueIds)
				this.dispatch({type: 'issues/issueIdsUpdated', data: issueIds})
        break;
      default:
        console.log("Unknown command received: " + message.type)
    }
  }

  reconnect() {
    if (!this.reconnecting) {
      this.reconnecting = true;
      setTimeout(() => {
        console.log("Trying to reconnect")
        this.connect()
        this.reconnecting = false;
      }, 1000);
    }
  }
}

const middleware = applyMiddleware(thunkMiddleware)

const store = createStore(counter, middleware)
const rootEl = document.getElementById('root')
const apiClient = new ApiClient(store.dispatch)
const issuesClient = new IssuesClient(store.dispatch, apiClient)
issuesClient.connect()

const render = () => ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to="/" className="navbar-brand">CRDT Issue Test App</Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">Issues</Link>
              </li>
              <li className="nav-item">
                <Link to="/issue" className="nav-link">New Issue</Link>
              </li>
            </ul>
          </div>

          <SavingIndicator/>
        </nav>
      </div>

			<div className="container">
				<Switch>
					<Route path="/issue">
						<IssueForm/>
					</Route>

					<Route path="/editIssue/:issueId">
						<IssueForm apiClient={apiClient}/>
					</Route>

					<Route path="/">
						<Issues apiClient={apiClient}/>
					</Route>
				</Switch>
		  </div>
    </Router>
  </Provider>,
  rootEl
)

render()
store.subscribe(render)
