import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormikContext, Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import * as Automerge from 'automerge'
const axios = require('axios');


function deepEqual(obj1, obj2) {

    if(obj1 === obj2) // it's just the same object. No need to compare.
        return true;

    if(isPrimitive(obj1) && isPrimitive(obj2)) // compare primitives
        return obj1 === obj2;

    if(Object.keys(obj1).length !== Object.keys(obj2).length)
        return false;

    // compare objects with same number of keys
    for(let key in obj1)
    {
        if(!(key in obj2)) return false; //other object doesn't have this prop
        if(!deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
}

function isPrimitive(obj)
{
    return (obj !== Object(obj));
}


class TimeoutDebouncer {
	constructor(timeout) {
		this.timeout = timeout
		this.timeoutId = false
	}

	debounce(f) {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId)
		}
		this.timeoutId = setTimeout(() => {
			this.timeoutId = false
			f()
		}, this.timoeout)		
	}
}
const debouncer = new TimeoutDebouncer(300)


const AutoSubmit = () => {
	// Grab values and submitForm from context
	const { values, submitForm } = useFormikContext();
	React.useEffect(() => {
	  // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
		console.log("Autosubmit")
		debouncer.debounce(() => {
			submitForm()
		})
	}, [values, submitForm]);
	return null;
};

const submitNewForm = (values, { setSubmitting, resetForm }) => {
	console.log(values)
	const newDoc = Automerge.change(Automerge.init(), doc => {
		doc.summary = values.issueSummary
		doc.priority = values.issuePriority
		doc.description = values.issueDescription
	});
	const data = JSON.stringify({type: 'newDocument', payload: Array.from(Automerge.save(newDoc))})
	axios.post('https://worker.datainfra-justinc.workers.dev/createIssue', data)
		.then(response => {
			// dispatch({type: 'issue/create', payload: response.data})
			window.location.href = '/';
			resetForm()
		}).catch(error => {
			console.log(error);
		}).finally(() => {
			setSubmitting(false);
		});
};


const IssueForm = (props) => {
  // eslint-disable-next-line
	const dispatch = useDispatch()
	let { issueId } = useParams();

	let initialValues = { issueSummary: '', issueDescription: '', issuePriority: 2}
	let submitHandler = submitNewForm
	let saveButtonText = 'Create Issue'
	if (issueId) {
		const docClient = props.apiClient.docClients.get(issueId)
		// TODO: This should wait for a client instead of redirecting.
		if (!docClient) {
			window.location.href = '/';
			return;
		}
		const doc = docClient.state
		initialValues = { issueSummary: doc.summary, issueDescription: doc.description, issuePriority: doc.priority}
		submitHandler = (values, { setSubmitting }) => {
			const dirty = !deepEqual(initialValues, values)
			if (dirty) {
				docClient.updateDoc((doc) => {
					doc.summary = values.issueSummary
					doc.priority = values.issuePriority
					doc.description = values.issueDescription
				})
			}
			setSubmitting(false)
		}
		saveButtonText = 'Save'
	}

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validate={
        values => {
          const errors = {};
          if (!values.issueSummary) {
            errors.issueSummary = 'Required';
          }
          if (!values.issueDescription) {
            errors.issueDescription = 'Required';
          }
          return errors;
        }
      }
    >
      {({ isSubmitting }) => (
        <Form className="">
          <div className="form-group">
            <label htmlFor="issueSummary">Summary</label>
            <Field type="text" placeholder="Issue Summary" className="form-control" name="issueSummary"/>
            <ErrorMessage name="issueSummary" component="div" />
          </div>

          <div className="form-group">
            <label htmlFor="issuePriority">Priority</label>
            <Field as="select" name="issuePriority" className="form-control">
             <option value='0'>P0 - Critical</option>
             <option value='1'>P1 - Major</option>
             <option value='2'>P2 - Minor</option>
            </Field>
          </div>

          <div className="form-group">
            <label htmlFor="issueDescription">Description</label>
            <Field type="textarea" className="form-control" name="issueDescription" rows="3"/>
            <ErrorMessage name="issueDescription" component="div" />
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{saveButtonText}</button>
          </div>
					{issueId &&
						<AutoSubmit/>
					}
        </Form>
      )}
    </Formik>
  )
}

export default IssueForm
