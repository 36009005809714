import React from 'react'
import {
  Link
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


const Issues = (props) => {
  // eslint-disable-next-line
	const dispatch = useDispatch()
	const docs = useSelector(state => {
		return new Map([...state.docs].filter(([k, v]) => v.data))
	})
	const status = useSelector(state => state.status)

	if (status === 'loading') {
		return "Loading..."
	}

	if (docs.size > 0) {
		const listItems = Array.from(docs.entries()).sort(([firstKey, firstDoc], [secondKey, secondDoc]) => {
			if (firstDoc.data.priority < secondDoc.data.priority) {
				return -1;
			}
			if (firstDoc.data.priority > secondDoc.data.priority) {
				return 1;
			}

			if (firstDoc.data.summary < secondDoc.data.summary) {
				return -1;
			}

			if (firstDoc.data.summary > secondDoc.data.summary) {
				return 1;
			}

			return 0;			
		}).map(([issueId, doc]) => 
			<tr key={issueId}>
				<td>P{doc.data.priority}</td>
				<td>{doc.data.summary}</td>
				<td>{doc.data.description}</td>
				<td><Link to={`/editIssue/${issueId}`}>Edit</Link></td>
				<td><button onClick={() => props.apiClient.destroyDocument(issueId)}>X</button></td>
			</tr>
		);

		return (
			<table className="table table-striped">
				<thead>
					<tr>
						<th scole="col">Priority</th>
						<th scole="col">Summary</th>
						<th scole="col">Description</th>
						<th scole="col">Edit</th>
						<th scole="col">Delete</th>
					</tr>
				</thead>
			  <tbody>
					{listItems}
			  </tbody>
			</table>
		)
	} else {
		return (
			<p>Loading...</p>
		)
	}
}

export default Issues
