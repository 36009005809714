import React from 'react'
import { useSelector } from 'react-redux'

const SavingIndicator = () => {
  const saving = useSelector(state => state.saving)

  if (!saving) {
    return (
      <p>✔️  Saved to Cloud</p>
    )
  } else {
    return (
      <p>🔄 Saving...</p>
    )
  }
}

export default SavingIndicator
